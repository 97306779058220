<template>
    <div class="auth-bg bg-cover">
        <div class="nav-container bg-transparent pt-3">
            <lego-nav-bar drop-down-animation="fade" drop-down-animation-mobile="fade" :colored="true" :items="menu"
                          shadow="0" class="container">
                <template #logo>
                    <router-link to="/">
                        <img src="../assets/web/logo/white-logo.svg" alt="">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>
        <div class="page-content">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
    </div>
</template>

<script>
import getMenu from '../data/menu';
import LegoNavBar from 'lego-framework/src/components/NavBar';

export default {
    components: {
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Medico Pharm',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};

</script>
<style lang="scss">
.auth-bg {
    background: url("../assets/web/auth/auth-bg-gradient.png");
    background-size: cover;
    @media (min-width: 992px) {
        background: url("../assets/web/auth/auth-bg-gradient.png");
        min-height: 100vh;
        //background-size: contain !important;
    }
    background-repeat: no-repeat;
    background-position: center;
}
</style>
